/**
 * Returns an array of numbers from start to stop with a step size of step.
 */
export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from({length: (stop - start) / step + 1}, (value, index) => start + index * step);

/**
 * Given an obj, returns a new object with the same keys and values, but with
 * all keys and values trimmed. If a value is not a string, nothing is done to
 * the value.
 *
 * @return a new object with all keys and string values trimmed
 */
export const trimKeysAndValuesInObject = <T>(obj: Record<string, T>): Record<string, T> => {
    const trimmedObject: Record<string, any> = {};
    Object.keys(obj).forEach(key => {
        const trimmedKey = key.trim();
        const value = obj[key];
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        trimmedObject[trimmedKey] = trimmedValue;
    });
    return trimmedObject;
};

/**
 * Sorts an object by its keys.
 *
 * Note that this relies on the insertion order resulting in consistent
 * iteration order. There may be some caveats when number-like strings are
 * used as keys.
 *
 * @return New shallow copy object with keys sorted alphabetically
 */
export const sortObjectKeys = <T>(obj: Record<string, T>): Record<string, T> => {
    const keys = Object.keys(obj).sort();
    const sortedObj: Record<string, T> = {};
    keys.forEach(key => {
        sortedObj[key] = obj[key];
    });
    return sortedObj;
};
