import React from 'react';
import Container from '@mui/material/Container';
import {Button, Typography} from '@mui/material';
import {useTrovoConfig} from '../../utils/config';
import {LogoSvgWithText} from '../../components/TrovoLogo';
import {useNavigate} from 'react-router-dom';
import {useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface VerifyThanksProps {}

const VerifyThanks: React.FC<VerifyThanksProps> = () => {
    const {user} = useTrovoConfig();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container
            sx={{
                paddingTop: '172px',
                ...(isMobile ? {paddingLeft: '20px', paddingRight: '20px'} : {}),
                width: isMobile ? '100%' : '1440px',
                height: '90vh'
            }}>
            <LogoSvgWithText width={isMobile ? 150 : 200} color="black" />
            <Typography variant="headline" size="large" sx={{marginTop: '60px', ...(isMobile && {fontSize: '24px'})}}>
                {`Thanks ${user.email}`}
            </Typography>
            <Typography variant="headline" size="small" marginTop="24px">
                Now that your account's been verified, you can select your access level.
            </Typography>

            <Button
                onClick={() => navigate('/plans')}
                variant="contained"
                sx={{
                    marginTop: '36px',
                    width: isMobile ? '100%' : '259px',
                    marginLeft: '-4px'
                }}>
                Continue
            </Button>
        </Container>
    );
};

export default VerifyThanks;
