import React, {useEffect} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {Check} from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ToDemoModal from './ToDemoModal';
import {tempCurrentUserDemoFeaturesList} from '../../../utils/mock-data';

interface DemoRectangleProps {
    current: boolean;
    planInfo: Plan;
    changePlan: () => Promise<string>;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const DemoRectangle: React.FC<DemoRectangleProps> = ({current, planInfo, changePlan, loading, setLoading}) => {
    const [modalOpen, setModalOpen] = React.useState(false);

    useEffect(() => {
        if (current) {
            setModalOpen(false);
        }
    }, [current]);

    return (
        <>
            <Box
                borderRadius="12px"
                border={current ? '4px solid' : '1px solid'}
                width="741px"
                bgcolor={current ? 'white' : 'surface-container-lowest'}
                padding="35px 46px 26px 44px"
                display="flex"
                flexDirection="column"
                gap="32px"
                style={{
                    background: current
                        ? 'linear-gradient(white, white) padding-box,linear-gradient(to right, #7C57F4, #F7218C) border-box'
                        : 'none',
                    borderRadius: '10px',
                    border: current ? '4px solid transparent' : '4px solid'
                }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="title" size="medium" color="on-surface">
                        Demonstration Access
                    </Typography>
                    <Button
                        data-test-id="demo-plan-button"
                        variant="contained"
                        onClick={() => setModalOpen(true)}
                        startIcon={current ? <Check /> : false}
                        sx={{width: '250px'}}
                        disabled={current}>
                        {current ? 'Current plan' : 'Change plan'}
                    </Button>
                </Box>
                <Box display="flex" flexDirection="column" gap="15px" marginBottom="20px">
                    <Box width="269px" display="flex" flexDirection="column" gap="10px" paddingTop="0px">
                        <Typography variant="title" size="large" color="on-surface-variant">
                            Demo Environment
                        </Typography>
                        <Typography variant="body" size="large" marginTop="2px" color="on-surface-variant">
                            FREE
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" gap="78px">
                        <Typography variant="label" size="large-prominent" width="245px" flexShrink="0" color="on-surface-variant">
                            Access to our demo environment allows researchers to explore the Trovomics platform and its basic features with
                            no time limits or payment commitments.
                        </Typography>
                        <List
                            sx={{
                                listStyleType: 'disc',
                                pl: 2,
                                width: '290px',
                                flexGrow: 0,
                                '& .MuiListItem-root': {display: 'list-item', padding: '0 0 0 4px'}
                            }}>
                            {tempCurrentUserDemoFeaturesList.map((feature, index) => (
                                <ListItem component={Typography} variant="body" size="small" color="on-surface-variant" key={index}>
                                    {feature}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Box>
            <ToDemoModal
                open={modalOpen}
                setClose={() => setModalOpen(false)}
                changePlan={changePlan}
                loading={loading}
                setLoading={setLoading}
            />
        </>
    );
};

export default DemoRectangle;
