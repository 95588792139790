import styled from '@emotion/styled';
import {Typography, TableHead, TableRow, TableCell, Checkbox, TableSortLabel} from '@mui/material';
import {Box} from '@mui/system';
import {visuallyHidden} from '@mui/utils';
import React from 'react';

export const FiltersContainer = styled('div')({
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

type Order = 'asc' | 'desc';
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
interface Data {
    variable: string;
    value: string[];
}
const headCells: readonly HeadCell[] = [
    {
        id: 'variable',
        numeric: false,
        disablePadding: true,
        label: 'VARIABLE'
    },
    {
        id: 'value',
        numeric: true,
        disablePadding: false,
        label: 'VALUE'
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow sx={{bgcolor: 'surface-container-highest'}}>
                <TableCell width={'15%'} align="left">
                    <Checkbox
                        sx={{color: 'outline'}}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell key={headCell.id} align="left" sortDirection={orderBy === headCell.id ? order : false} width={'30%'}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            <Typography variant="body" size="medium">
                                {' '}
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell> </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;
