import React, {useEffect, useState} from 'react';
import {Box, Typography, InputBase} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {alpha, styled} from '@mui/material/styles';
import {Button} from '../../../../components/buttons/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EnhancedTableHead from './EnhancedTableHead';

export const SelectedRowsTextStyled = styled(Typography)({
    fontSize: 14
});
export const FilterTitleStyled = styled(Typography)({
    fontSize: 16,
    fontWeight: 600
});

export const FiltersContainer = styled('div')({
    display: 'flex',
    paddingBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const FiltersWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #CAC4D0',
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}));
const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'black',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}));
const FilterButtonStyled = styled(Button)({
    width: 200,
    backgroundColor: '#E8DEF8',
    borderRadius: '5px',
    '&:hover': {
        outline: '1px solid #6750A4'
    }
});
const MenuStyled = styled(Menu)({
    padding: '0',
    '& .MuiList-root': {
        padding: '0'
    }
});
const MenuItemStyled = styled(MenuItem)({
    display: 'flex',
    minWidth: '750px',
    padding: '10px 20px',
    textAlign: 'left',
    '&:not(:last-child)': {
        borderBottom: '1px solid #ccc'
    }
});
interface Data {
    variable: string;
    value: string[];
}

type Order = 'asc' | 'desc';

export const EnhancedTable = (props: {formData: any; setFormData: any; handleNext: any; groupLevels: any; error: any; setError: any}) => {
    const {formData, setFormData, handleNext, groupLevels, error, setError} = props;
    const {group_options: selectedGroupOption} = formData;
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('variable');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState<any>([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const isFilerOpen = Boolean(anchorEl);

    console.log('groupLevels', groupLevels);
    const formatRows = (obj: {[s: string]: string}) => {
        const newRows = Object?.entries(obj).map(([key, value]) => {
            return {
                variable: key
                    .split('_')
                    .map(word => word[0].toUpperCase() + word.slice(1))
                    .join(' '),
                value: value
            };
        });
        setRows(newRows.filter(r => r.variable !== 'Flag'));
    };

    useEffect(() => {
        formatRows(groupLevels);
    }, [groupLevels]);

    useEffect(() => {
        selectedGroupOption.length < 1 || selectedGroupOption.length > 2 || !selectedGroupOption.every((o: any) => o.value)
            ? setError(`Please select between 1 and 2 options and select a control for each before continuing.`)
            : setError(null);
    }, [selectedGroupOption]);

    useEffect(() => {
        error ? handleNext(false) : handleNext(true);
    }, [error]);

    const handleFilterClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n: {variable: string}) => ({name: n.variable}));
            setFormData((prev: any) => {
                return {
                    ...prev,
                    group_options: newSelected
                };
            });
            return;
        }
        setFormData((prev: any) => {
            return {
                ...prev,
                group_options: []
            };
        });
    };
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selectedGroupOption?.findIndex((item: any) => item.name === name);
        let newSelected: {name: string}[] = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedGroupOption, {name}];
        } else {
            newSelected = [...selectedGroupOption.slice(0, selectedIndex), ...selectedGroupOption.slice(selectedIndex + 1)];
        }
        setFormData((prev: any) => {
            return {
                ...prev,
                group_options: newSelected
            };
        });
    };
    const handleControlClick = (name: string, value: string) => {
        setFormData((prev: any) => {
            return {
                ...prev,
                group_options: [
                    ...selectedGroupOption?.map((item: any) => {
                        return item.name === name ? {name, value: value} : item;
                    })
                ]
            };
        });
    };
    const isSelected = (name: string) => selectedGroupOption?.map((e: any) => e.name).indexOf(name) !== -1;
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box p={4} mr={5} sx={{borderRadius: '12px', bgcolor: 'surface-container-lowest'}}>
            <Box sx={{width: '100%', mb: 2}}>
                <TableContainer sx={{border: '1px solid', borderColor: 'outline', borderRadius: '6px'}}>
                    <Table aria-labelledby="tableTitle" size="medium">
                        <EnhancedTableHead
                            numSelected={selectedGroupOption?.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows?.length}
                        />
                        <TableBody>
                            {rows?.map((row: any, index: any) => {
                                const isItemSelected = isSelected(row.variable);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.variable}
                                        selected={isItemSelected}
                                        sx={{cursor: 'pointer', borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
                                        <TableCell width={'15%'} align="left">
                                            <Checkbox
                                                sx={{color: 'outline'}}
                                                checked={isItemSelected}
                                                onClick={event => handleClick(event, row.variable)}
                                                inputProps={{
                                                    'aria-labelledby': labelId
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none" align="left" width={'30%'}>
                                            <Typography variant="body" size="medium" pl={2}>
                                                {row.variable}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left" width={'30%'}>
                                            {row?.value?.map((value: any) => (
                                                <Typography variant="body" size="medium" sx={{padding: '13px 0'}}>
                                                    {value}
                                                </Typography>
                                            ))}
                                        </TableCell>
                                        <TableCell align="left">
                                            {isItemSelected && (
                                                <>
                                                    <FormControl>
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="optionOne"
                                                            name="radio-buttons-group"
                                                            onChange={e => handleControlClick(row.variable, e.target.value)}>
                                                            {row.value.map((val: any) => (
                                                                <FormControlLabel
                                                                    value={val}
                                                                    control={<Radio size="small" />}
                                                                    label={
                                                                        <Typography variant="body" size="medium">
                                                                            Set as control
                                                                        </Typography>
                                                                    }
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows
                                    }}>
                                    <TableCell colSpan={2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};
