// TODO: Move presignedUrl and validate file here? Also centralise other uploadfile uses?
// Would it work as just this file? Since those functions use auth0 and appconfig

interface UploadHooks {
    /**
     * Called every time a single file is uploaded successfully.
     */
    onSuccess?: (url: string, response: Response) => void;
    /**
     * Called every time a single file upload fails.
     */
    onError?: (url: string, error: Error) => void;
    /**
     * Called every time a single file upload is retried.
     */
    onRetry?: (url: string, attempt: number) => void;
}

interface UploadOptions {
    maxConcurrent?: number;
    maxRetries?: number;
    hooks?: UploadHooks;
}

/**
 * Uploads a list of files to a list of URLs. Uploads are done concurrently up
 * to a maximum. Uploads are retried up to a maximum.
 *
 * @param urls The URLs to upload the files to.
 * @param files The files to upload, must be the same length as `urls`.
 */
export const uploadFiles = async (urls: Readonly<string[]>, files: Readonly<File[]>, options: UploadOptions = {}) => {
    const {maxConcurrent = 3, maxRetries = 2, hooks = {}} = options;
    const queue = urls.map((url, index) => ({
        url,
        file: files[index]
    }));

    const activeUploads = new Set<Promise<Response>>();

    async function uploadWithRetry(url: string, file: File, attempts: number): Promise<Response> {
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'text/plain'
                },
                body: file
            });
            if (!response.ok) {
                throw new Error(`Upload failed: ${response.status}`);
            }
            hooks.onSuccess?.(url, response);
            return response;
        } catch (error) {
            if (attempts < maxRetries) {
                hooks.onRetry?.(url, attempts + 1);
                return uploadWithRetry(url, file, attempts + 1);
            }
            hooks.onError?.(url, error as Error);
            throw error;
        }
    }

    while (queue.length > 0 || activeUploads.size > 0) {
        while (activeUploads.size < maxConcurrent && queue.length > 0) {
            const item = queue.shift()!;
            const uploadPromise = uploadWithRetry(item.url, item.file, 0).finally(() => activeUploads.delete(uploadPromise));
            activeUploads.add(uploadPromise);
        }

        // First promise to resolve or reject. Rest continues running still.
        await Promise.race(activeUploads);
    }
};
