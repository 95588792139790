import React from 'react';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

interface PaginationBarProps {
    perPage: number;
    setPerPage: (value: number) => void;
    page: number;
    setPage: (value: number) => void;
    totalPages: number;
}

const PaginationBar: React.FC<PaginationBarProps> = ({perPage, setPerPage, page, setPage, totalPages}) => {
    const handlePageChange = (event: any, value: number) => {
        setPage(value);
    };

    const handlePerPageChange = (event: any) => {
        setPerPage(event.target.value);
        setPage(1);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2}>
            <Box display="flex" alignItems="center">
                <FormControl variant="outlined" size="small">
                    <Select sx={{px: 1}} value={perPage} onChange={handlePerPageChange}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={300}>300</MenuItem>
                    </Select>
                </FormControl>
                <Box ml={1} display="flex" alignItems="center">
                    <InputLabel>Samples per page</InputLabel>
                </Box>
            </Box>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} variant="outlined" shape="rounded" />
        </Box>
    );
};

export default PaginationBar;
