import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Typography, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import CreationFlowBar from '../../components/CreationFlowBar';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import {
    BackupOutlined,
    CheckCircleOutlineRounded,
    IntegrationInstructionsOutlined,
    PendingActions,
    PollOutlined
} from '@mui/icons-material';
import Step3 from './steps/step3/Step3Index';
import Step4 from './steps/step4/IndexStep4';
import Step5 from './steps/Step5';
import {useAuth0} from '@auth0/auth0-react';
import {getAppConfig, useBff, useTrovoConfig} from '../../utils/config';
import {Error} from '../../components/Error';
import BannerMessage from '../AccountDetails/BannerMessage';
import {Loading} from '../../components/Loading';
import Popup from '../../components/popup';
import {SamplesProgressModal} from './SamplesProgModal';
import {trimKeysAndValuesInObject} from '../../utils/helpers';
import {uploadFiles} from '../../utils/file-upload';

const ExpContainer = styled(Box)({
    display: 'flex',
    margin: '0 auto',
    width: 'fit-content',
    paddingTop: '48px',
    gap: '20px'
});

const FormContainer = styled(Box)({
    // flex: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '990px',
    gap: 10,
    marginBottom: '55px'
});

const formDataInitialState = {
    experiment_details: {
        name: '',
        description: '',
        organism: '',
        sequencing_type: ''
    },
    sequencing_details: {
        analyzed_molecule: '',
        rna_selection_method: '',
        sequencing_adapter: '',
        sequencing_platform: '',
        platform_model: '',
        sequencing_read_type: '',
        sequencing_sense: '',
        files_per_sample: ''
    },
    step_three_details: {},
    step_four_details: {},
    archived: false
};

function CreateExperiment() {
    const {getAccessTokenSilently} = useAuth0();
    const {apiHost} = getAppConfig();
    const {id} = useParams();
    const navigate = useNavigate();
    const {user} = useTrovoConfig();
    const [samples, setSamples] = useState([] as any[]);
    const [step, setStep] = useState(0);
    const [experiment, setExperiment] = useState(null);
    const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress>({
        processing: false,
        createSamplesDone: false,
        getPresignedUrlsDone: false,
        uploadFilesDone: false,
        doneCount: 0,
        totalFiles: 0
    });
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState<{
        experiment_details: ExperimentDetails;
        sequencing_details: SequencingDetails;
        step_three_details: StepThreeDetails;
        step_four_details: StepFourDetails;
    }>(formDataInitialState);

    /**
     * Calls validate-file-upload for the file in the sample. Note that this is
     * an endpoint with side-effects. The file information is compared to what
     * is found in S3. The database is updated with the result of the
     * comparison.
     *
     * @return success is true if file is validated
     */
    const validateFile = async (sample: {id: string}, file: {id: string}): Promise<{success: boolean; message: string}> => {
        const response = await fetch(`${apiHost}/experiment/${id}/sample/${sample.id}/file/${file.id}/validate-file-upload`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            }
        });
        const status = await response.json();
        return status;
    };

    /**
     * Calls the BFF `/experiment/:experiment_id/sample` endpoint to fetch the
     * samples for the current experiment. Results are set to the samples
     * state.
     */
    const fetchSamples = async () => {
        if (!id) return;
        try {
            const response = await fetch(`${apiHost}/experiment/${id}/sample`, {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            });
            const samples: any[] = await response.json();
            await Promise.all(
                samples.map(async s => {
                    if (s.files !== undefined) {
                        await Promise.all(s.files.map((f: any) => validateFile(s, f)));
                    }
                })
            );
            console.log('All files validated.');
            samples.sort((a, b) => new Date(a.created_time).getTime() - new Date(b.created_time).getTime());
            setSamples(samples);
        } catch (err: any) {
            console.error(err.message || 'An error occurred.');
        }
    };

    /**
     * If an experiment ID is present, it means the user is continuing a draft
     * experiment. This function fetches the experiment data from the API and
     * sets the state of the form, the steps, and the experiment to match what
     * was already saved.
     *
     * Uses `setExperiment`, `setFormData`, `setStep` (indirectly), and
     * `setLoading`.
     */
    const fetchData = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const response = await fetch(`${apiHost}/experiment/${id}`, {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            });
            const data = await response.json();
            determineStepBasedOnData(data);
            setExperiment(data);
            const {
                name,
                description,
                organism,
                sequencing_type,
                analyzed_molecule,
                rna_selection_method,
                sequencing_adapter,
                sequencing_platform,
                platform_model,
                sequencing_read_type,
                sequencing_sense,
                files_per_sample,
                sample_count
            } = data;

            setFormData({
                experiment_details: {name, description, organism, sequencing_type},
                sequencing_details: {
                    analyzed_molecule,
                    rna_selection_method,
                    sequencing_adapter,
                    sequencing_platform,
                    platform_model,
                    sequencing_read_type,
                    sequencing_sense,
                    files_per_sample
                },
                step_three_details: {},
                step_four_details: {}
            });
        } catch (err: any) {
            console.error(err.message || 'An error occurred.');
        } finally {
            setLoading(false);
        }
    };

    /**
     * Sets step state based on whether certain properties of the given data
     * have a truthy value.
     *
     * Uses `setStep`.
     */
    const determineStepBasedOnData = (data: any) => {
        if (!data.name || !data.organism || !data.sequencing_type) {
            setStep(0);
        } else if (!data.analyzed_molecule || !data.sequencing_read_type || !data.files_per_sample) {
            setStep(1);
        } else if (data.sample_count === 0) {
            setStep(2);
        } else {
            setStep(3);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchSamples();
    }, [step]);

    /**
     * Saves current experiment details to the BFF. Creates new experiment if
     * there was no `id` yet (and redirects to a url with an `id` if so).
     * Updates existing experiment if there was an `id`.
     *
     * Updates step state or redirects to home.
     */
    const handleSave = async (whatToSave: ExperimentDetails | SequencingDetails | null | {pending: false}) => {
        if (whatToSave) {
            try {
                const body = id ? {...whatToSave} : {...whatToSave, archived: false, pending: true, group_options: {}};
                const endpoint = id ? `${apiHost}/experiment/${id}` : `${apiHost}/experiment/create`;
                const method = id ? 'PUT' : 'POST';
                const response = await fetch(endpoint, {
                    method: method,
                    headers: {
                        Authorization: `Bearer ${await getAccessTokenSilently()}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                const responseData = await response.json();
                if (response.ok) {
                    if (!id) {
                        navigate(`/experiment/draft/${responseData.id}`);
                    }
                } else {
                    console.error(responseData.message || 'An error occurred.');
                }
            } catch (err: any) {
                console.error(err.message || 'An error occurred.');
            }
        }
        step === 4 ? navigate('/') : setStep(step + 1);
    };

    const createSamples = async (obj: any) => {
        let totalCount = 0;
        setFileUploadProgress(state => ({...state, processing: true}));

        // Create samples and file info in the BFF. End up with created samples.
        const samplesPromises = obj.values_by_sample.map((sample: any) => {
            const filenameArray = obj.files_list[sample.SampleName] || [];
            const fileObjects = filenameArray.map((filename: any) => obj.fastq_files.find((file: any) => file.name === filename));
            return createSample(sample, fileObjects);
        });
        const responses = await Promise.all(samplesPromises);
        const samples = await Promise.all(responses.map(response => response.json()));

        setFileUploadProgress(state => ({...state, createSamplesDone: true}));

        // Fetch presigned URLs for every file to upload.
        const presignedUrlPromises = samples.flatMap((sample: any) => {
            const files = sample.files;
            if (Array.isArray(files)) {
                return files?.map((f: any) => {
                    totalCount++;
                    return getPresignedUrl(sample.id, f.id, f.name);
                });
            } else {
                return [];
            }
        });
        const urlsAndFiles: {url: string; filename: string}[] = await Promise.all(presignedUrlPromises);

        setFileUploadProgress(state => ({...state, getPresignedUrlsDone: true, totalFiles: totalCount}));

        // Find the file objects for each url
        const urls: string[] = [];
        const files: File[] = [];
        urlsAndFiles.forEach(f => {
            const file: File | undefined = obj?.fastq_files?.find((ff: File) => ff.name === f.filename);
            if (file === undefined) {
                console.error(`File ${f.filename} not found in fastq_files array.`);
            } else {
                urls.push(f.url);
                files.push(file);
            }
        });

        // Upload files to their presigned URLs
        await uploadFiles(urls, files, {
            hooks: {
                onSuccess: (_url: string, _response: Response) => {
                    setFileUploadProgress(prevState => ({...prevState, doneCount: prevState.doneCount + 1}));
                },
                onError: (url: string, error: Error) => {
                    console.error(`Error uploading file to ${url}: ${error}`);
                },
                onRetry: (url: string, attempt: number) => {
                    console.log(`Retrying upload of file to ${url} (attempt ${attempt})`);
                }
            }
        });

        // TODO: Actually do something when a file is not uploaded. At least for now, the next page will notice when validate-file-upload fails.

        // Refresh state of the form by fetching all samples from DB.
        await fetchSamples();

        setFileUploadProgress(state => ({...state, processing: false, uploadFilesDone: true}));
        setStep(3);
    };

    /**
     * Creates a single sample in the BFF. Expects the uploading file objects
     * too to get their sizes.
     */
    const createSample = async (sample: any, fileObjects: any): Promise<Response> => {
        const {SampleName: sampleName, Filename: filename, ...other_categories} = sample;
        const otherCategoriesTrimmed = trimKeysAndValuesInObject(other_categories);
        const fileObjectsToInsert = fileObjects.map((obj: any) => {
            // Names follow a specific format that includes lane and read
            // number separated by underscores.
            const filename = obj.name;
            const filenameSplit = filename.split('_');
            const laneNumber = filenameSplit.length >= 5 ? filenameSplit[filenameSplit.length - 3][3] : '1';
            const readNumber =
                filenameSplit.length >= 5
                    ? filenameSplit[filenameSplit.length - 2][1]
                    : filenameSplit.length === 2
                      ? filenameSplit[1][0]
                      : '1';
            return {
                name: filename,
                original_size: obj.size,
                strandedness_seq_sense: formData.sequencing_details.sequencing_sense,
                strandedness_seq_end:
                    formData.sequencing_details.sequencing_read_type && formData.sequencing_details.sequencing_read_type.toLowerCase(),
                interleaved: false,
                status: 'unknown',
                lane_number: laneNumber,
                read_number: readNumber
            };
        });

        const sampleRecord = {
            name: sampleName,
            organism: formData.experiment_details.organism,
            analyzed_molecule: formData.sequencing_details.analyzed_molecule,
            sequencing_instrument_platform: formData.sequencing_details.sequencing_platform,
            sequencing_instrument_model: formData.sequencing_details.platform_model,
            experiment_id: id,
            other_categories: otherCategoriesTrimmed,
            files: fileObjectsToInsert
        };
        return fetch(`${apiHost}/experiment/${id}/sample/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`
            },
            body: JSON.stringify(sampleRecord)
        });
    };

    /**
     * Contact BFF to get a presigned AWS URL to which the file can be uploaded.
     */
    const getPresignedUrl = async (sampleId: string, fileId: string, filename: string): Promise<{url: string; filename: string}> => {
        return fetch(`${apiHost}/experiment/${id}/sample/${sampleId}/file/${fileId}/get-presigned-url`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}}`
            }
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                return {url: data.url, filename: filename};
            });
    };

    const steps = [
        {
            name: 'Enter Experiment Details',
            caption: 'Record your experiment name, description, and other identifying information.',
            description:
                'Record the identifying details of your experiment. You can use this information to create a unique identifier for your experiment, such as “Experiment 1: Transcriptome of analysis of human heart failure.” We recommend using clear, descriptive naming conventions to help you organize and differentiate between your experiments. ',
            component: (
                <Step1
                    formData={formData}
                    setExperimentDetails={(obj: ExperimentDetails) => {
                        setFormData(state => ({...state, experiment_details: obj}));
                        handleSave(obj);
                    }}
                />
            ),
            icon: <PendingActions sx={{fill: step === 0 ? 'url(#linearColors)' : ''}} />
        },
        {
            name: 'Enter Sequencing Details',
            caption: 'Enter required information about your data files.',
            description:
                'Select the configuration that corresponds with your data files as provided by the organization that generated them.',
            component: (
                <Step2
                    formData={formData}
                    setSequencingDetails={(obj: SequencingDetails) => {
                        setFormData(state => ({...state, sequencing_details: obj}));
                        handleSave(obj);
                    }}
                />
            ),
            icon: <PollOutlined sx={{fill: step === 1 ? 'url(#linearColors)' : ''}} />
        },
        {
            name: 'Build Your Experiment',
            caption: 'Format and upload your sample information.',
            component: <Step3 experiment={experiment} createSamples={createSamples} samples={samples} setStep={setStep} />,
            icon: <BackupOutlined sx={{fill: step === 2 ? 'url(#linearColors)' : ''}} />
        },
        {
            name: 'Edit Samples',
            caption: 'Review and edit the labels of sample variables and values.',
            description:
                'Make global changes to the variable and value labels you are using throughout this experiment. Changes to these labels will appear in any sample to which they are assigned.',
            component: <Step4 experiment={experiment} setFormData={setFormData} setStep={setStep} />,
            icon: <IntegrationInstructionsOutlined sx={{fill: step === 3 ? 'url(#linearColors)' : ''}} />
        },
        {
            name: 'Review & Create',
            caption: 'Check your work before finalizing this experiment.',
            component: (
                <Step5
                    experiment={experiment}
                    formData={formData}
                    samples={samples}
                    setStep={setStep}
                    submitExperiment={() => handleSave({pending: false})}
                />
            ),
            icon: <CheckCircleOutlineRounded sx={{fill: step === 4 ? 'url(#linearColors)' : ''}} />
        }
    ];

    return (
        <Box>
            {loading || fileUploadProgress.processing ? (
                loading ? (
                    <Loading />
                ) : (
                    <Popup isOpen={fileUploadProgress ? true : false}>
                        <SamplesProgressModal fileUploadProgress={fileUploadProgress} />
                    </Popup>
                )
            ) : (
                <ExpContainer>
                    <CreationFlowBar title="CREATE NEW EXPERIMENT" steps={steps} step={step} setStep={setStep} />

                    <FormContainer>
                        <Box sx={{marginBottom: '40px'}}>
                            <BannerMessage
                                show={user.cb_item_price_id?.includes('demo')}
                                setHide={() => null}
                                showClose={false}
                                title="Upgrade to a paid plan to unlock all features."
                                message="You are currently using a demo account."
                            />
                        </Box>
                        <Typography variant="headline" size="large">
                            {steps[step].name}
                        </Typography>
                        <Typography variant="body" size="medium" mb={2}>
                            {steps[step].description}
                        </Typography>
                        <div
                            style={{
                                margin: '2px 0',
                                padding: step !== 3 ? 20 : 0,
                                background: step !== 3 ? 'white' : '',
                                borderRadius: 10
                            }}>
                            {steps[step].component}
                        </div>
                    </FormContainer>
                </ExpContainer>
            )}
        </Box>
    );
}

export default CreateExperiment;
