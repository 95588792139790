import React from 'react';
import {styled} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {Typography} from '@mui/material';
import {LogoSvgWithText} from './TrovoLogo';

const FooterStyled = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    height: '76px',
    padding: '5px',
    backgroundColor: 'black',
    color: 'white',
    boxShadow: '0px 2px 4px 0px #00000033'
});

const FooterContainerStyled = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px'
});

const FooterLinksStyled = styled(Link)({
    fontSize: '14px',
    marginLeft: '30px',
    color: 'white',
    '&:hover': {
        textDecoration: 'underline',
        color: 'white'
    }
});

const mailto_body = `Please provide a detailed description of your issue or question.

Name: …
I'm having a problem with: Plans and Billing/Create Experiment/Create Analysis/Visualizer/Manage Samples/Other

Problem description:
…`;
const mailto_url = `mailto:${encodeURIComponent('Trovomics Support <support@trovomics.com>')}?subject=${encodeURIComponent('Support Request')}&body=${encodeURIComponent(mailto_body)}`;

export const Footer = () => {
    return (
        <FooterStyled>
            <FooterContainerStyled>
                <LogoSvgWithText width={145} height={30} />
                <FooterLinksStyled to={mailto_url} target="_blank">
                    SUBMIT A SUPPORT REQUEST
                </FooterLinksStyled>
                <FooterLinksStyled to="https://trovomics.com/help-center" target="_blank">
                    HELP CENTER
                </FooterLinksStyled>
                <FooterLinksStyled to="https://trovomics.com/faq" target="_blank">
                    FAQs
                </FooterLinksStyled>
            </FooterContainerStyled>
            <FooterContainerStyled>
                <FooterLinksStyled
                    to="https://app.termly.io/document/terms-of-service/d2b30385-785f-46fb-8294-1c4b7bf8393d"
                    target="_blank">
                    TERMS & CONDITIONS
                </FooterLinksStyled>
                <FooterLinksStyled to="https://app.termly.io/document/privacy-policy/3560bedf-89a9-403e-8b5e-deaec0c3b137" target="_blank">
                    PRIVACY POLICY
                </FooterLinksStyled>
                <Typography sx={{fontSize: 14, marginLeft: 3, color: 'white'}}>©2025, ALL RIGHTS RESERVED</Typography>
            </FooterContainerStyled>
        </FooterStyled>
    );
};
