import React, {startTransition} from 'react';
import {Typography, Box} from '@mui/material';
import {Button} from '../../components/buttons/Button';
import {styled} from '@mui/material/styles';
import {ArrowBack} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

const NavContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '310px',
    gap: 15,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '4px',
    padding: '0 1%'
});
const NavBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    width: '100%',
    gap: 3,
    paddingTop: '3%'
});

const ActiveBox = styled(Box)({
    display: 'flex',
    gap: 15,
    padding: '3.5% 4%',
    border: '0px',
    borderRadius: '12px',
    alignItems: 'flex-start',
    margin: '7px 3px'
});

const CreationFlowBar = ({title, steps, step, setStep}: any) => {
    const navigate = useNavigate();
    return (
        <NavContainer>
            <Button
                variant="outlined"
                size="small"
                startIcon={<ArrowBack />}
                fullWidth
                sx={{color: 'on-secondary-container'}}
                onClick={() => {
                    startTransition(() => {
                        navigate('/');
                    });
                }}>
                <Typography variant="label" size="large">
                    Back to Main Dashboard
                </Typography>
            </Button>
            <NavBox sx={{bgcolor: 'surface-container'}}>
                <Typography variant="label" size="medium-prominent" sx={{margin: '3.5% 7%'}}>
                    {title}
                </Typography>
                <Box sx={{padding: '1.75%', bgcolor: 'surface-container-lowest', borderRadius: '0 0 12px 12px'}}>
                    {steps.map((s: any, idx: number) => {
                        if (idx === step) {
                            return (
                                <ActiveBox key={idx} sx={{bgcolor: 'primary.main'}}>
                                    <Box mt={'-25px'}>
                                        <svg width={0} height={0}>
                                            {' '}
                                            <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
                                                <stop offset={0} stopColor="#7C57F4" />
                                                <stop offset={1} stopColor="#F7218C" />
                                            </linearGradient>
                                        </svg>{' '}
                                        {s.icon}
                                    </Box>
                                    <div>
                                        <Typography variant="body" size="medium" color={'on-primary'} mb={1}>
                                            {s.name}
                                        </Typography>
                                        <Typography variant="body" size="small" color={'on-primary'}>
                                            {s.caption}
                                        </Typography>
                                    </div>
                                </ActiveBox>
                            );
                        } else if (idx > step) {
                            return (
                                <ActiveBox
                                    key={idx}
                                    sx={{
                                        margin: '5% 0',
                                        padding: '0 5%',
                                        color: 'disabled-on-surface',
                                        opacity: '60%',
                                        cursor: 'not-allowed'
                                    }}>
                                    <i>{s.icon}</i>
                                    <Typography variant="body" size="medium">
                                        {s.name}
                                    </Typography>
                                </ActiveBox>
                            );
                        } else {
                            return (
                                <ActiveBox
                                    key={idx}
                                    sx={{margin: '5% 0', padding: '0 5%', color: 'primary.main', cursor: 'pointer'}}
                                    onClick={() => setStep(idx)}>
                                    <i>{s.icon}</i>
                                    <Typography variant="body" size="medium">
                                        {s.name}
                                    </Typography>
                                </ActiveBox>
                            );
                        }
                    })}
                </Box>
            </NavBox>
        </NavContainer>
    );
};

export default CreationFlowBar;
