import React, {useState} from 'react';
import PlanSelection from '../PlanSelection';
import Pay from '../Pay';

interface PlansAndPayProps {}

const PlansAndPay: React.FC<PlansAndPayProps> = () => {
    const [plan, setPlan] = useState<Plan | null>(null);

    if (!plan) {
        return <PlanSelection key="1" setPlan={setPlan} />;
    } else {
        return <Pay key="2" plan={plan} unselectPlan={() => setPlan(null)} />;
    }
};

export default PlansAndPay;
