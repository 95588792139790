import {useState, useEffect, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth0, LogoutOptions} from '@auth0/auth0-react';
import {Config} from '../types';

const getWindowProperty = <T>(property: any, defaultValue: T): T => {
    if (typeof window !== 'undefined' && window[property]) {
        return window[property] as T;
    }
    return defaultValue;
};

// Environment-specific config not needed since env vars updated in docker according to environment
// if no env vars are present, the application will by default use the development Auth0 tenant
const auth0Config = {
    auth0Domain: getWindowProperty<string>('AUTH0_TENANT', 'dev-eoxtei4d.us.auth0.com'),
    auth0ClientId: getWindowProperty<string>('AUTH0_CLIENT_ID', 'xp69HW4bQFnKfeMBJaWqVF0cMTya4Ufi'),
    auth0Audience: getWindowProperty<string>('AUTH0_AUDIENCE', 'https://api.trovomics.com')
    // other properties...
};

const appConfig: Config = {
    // Do not hardcode api values. The UI needs to behave appropriately based on the environment
    // If you wish to use a different api host, you can set the OR ("||") operator to the desired value
    // Local development will not have an environment variables set, so the default value will be used
    apiHost: (window as any).BFF_URL || 'https://api.dev.trovomics.com',
    apiVersion: '',
    buildRoute: function (path: string) {
        return `${this.apiHost}/${this.apiVersion}${path}`;
    },
    ...auth0Config
};

export const getAppConfig = () => {
    return appConfig;
};

export function setAuthHeader(token: string) {
    return {
        Authorization: `Bearer ${token}`
    };
}

/**
 * Wraps the auth0 logout function. First makes an API call to the backend to
 * remove the httponly appSession cookie. Then calls the provided auth0 logout
 * function.
 *
 * This extra work is necessary because the cookie is set by the visualizer,
 * but cannot be reached by the React UI.
 *
 * @param logout - The auth0 logout function, get it from useAuth0(). This will
 *            be called after the backend /logout API call.
 * @param getAccessTokenSilently - The auth0 getAccessTokenSilently function.
 *            This is required to get the token to contact the backend
 */
function wrapAuth0Logout(logout: (options?: LogoutOptions) => Promise<void>, getAccessTokenSilently: () => Promise<string>) {
    return async function (options?: LogoutOptions) {
        const token = await getAccessTokenSilently();
        const res = await fetch('/visualizer/api/delete-appsession-cookie', {
            method: 'POST',
            headers: {...setAuthHeader(token)}
        });
        if (res.status !== 200) {
            console.error('Failed to logout from backend');
        }
        // Call auth0
        await logout(options);
    };
}

export function useTrovoConfig() {
    const auth0 = useAuth0();
    const navigate = useNavigate();
    const {getAccessTokenSilently, user, isAuthenticated, isLoading, logout} = auth0;
    const location = useLocation();
    const currentPage = location.pathname;

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            navigate('/login');
            return;
        }
        if (isAuthenticated && !isLoading && user && user.email_verified === false) {
            navigate('/verify');
            return;
        }
        if (
            isAuthenticated &&
            !isLoading &&
            user &&
            !user.subscription_id &&
            user.cb_subscription_status !== 'active' &&
            user.cb_subscription_status !== 'non_renewing'
        ) {
            if (currentPage !== '/verified' && currentPage !== '/plans' && currentPage !== '/pay') {
                navigate('/verified');
            }
            return;
        }
    }, [isAuthenticated, isLoading, navigate, user]);
    return {
        ...auth0,
        // override auth0 logout function
        logout: wrapAuth0Logout(logout, getAccessTokenSilently),
        user: {...user}
    };
}
type reqError = {
    status?: number;
    error?: string;
};

/**
 * (Sort of?) custom hook to make requests to the BFF.
 *
 * @param method - HTTP method
 * @param path - API path (e.g. experiment)
 * @param body - Request body
 * @returns A tuple of [parsed response, error, isLoading, refetch]. The first
 * three are the stateful value of a useState. The final is a function the
 * refetches the data (and thus updates the previous states).
 */
export function useBff(method: string, path: string, body?: BodyInit): [any, reqError, boolean, () => void] {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null as any);
    const [error, setError] = useState({
        status: 0,
        error: undefined
    } as reqError);
    const {getAccessTokenSilently} = useAuth0();

    const apiConfig = getAppConfig();
    const getData = useCallback(
        async function () {
            try {
                const token = await getAccessTokenSilently();
                const opt: RequestInit = {
                    method,
                    headers: {
                        ...setAuthHeader(token),
                        'Content-Type': 'application/json'
                    }
                };
                if (body) {
                    opt.body = body;
                }
                const res = await fetch(apiConfig.buildRoute(path), opt);
                if (res.status !== 200) {
                    setError({
                        status: res.status,
                        error: (await res.text()) || ''
                    });
                    return;
                }
                const data = await res.json();

                setData(data);
            } catch (e: any) {
                setError({
                    error: e.toString()
                });
            }
        },
        [body, apiConfig, path, method, getAccessTokenSilently]
    );

    const reFetch = useCallback(() => {
        setError({status: 0, error: undefined});
        setIsLoading(true);
        getData().then(() => setIsLoading(false));
    }, [getData]);

    useEffect(() => {
        setIsLoading(true);
        getData().then(() => setIsLoading(false));
    }, [getData]);

    return [data, error, isLoading, reFetch];
}
