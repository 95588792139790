import React, {MouseEventHandler} from 'react';
import {AppBar, Toolbar, Box, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {ProfileMenu} from './ProfileMenu';
import {User} from '@auth0/auth0-react';
import {LogoSvg} from './TrovoLogo';

const TrovoBar = styled(AppBar)({
    padding: '10px 0',
    zIndex: 1200,
    background: 'primary',
    color: 'white',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
    '& .MuiToolbar-root': {
        minHeight: 40,
        alignItems: 'center'
    },
    marginBottom: '.2%'
});

const StackedRightText = styled(Typography)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

const TitleStyled = styled(Typography)({
    display: 'flex',
    height: 50,
    padding: '0 15px',
    alignItems: 'center',
    borderLeft: '2px solid #79747E',
    fontSize: '24px'
});

type NavProps = {
    user?: User; // TrovoUser
    logout: MouseEventHandler;
};

export function Nav({user, logout}: NavProps) {
    return (
        <TrovoBar position={'static'}>
            <Toolbar>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center'}}>
                    <LogoSvg width={38} />
                    <TitleStyled>Trovomics</TitleStyled>
                </Box>
                <Box flexGrow="1" />
                <StackedRightText>Hello, {user?.name}</StackedRightText>
                <ProfileMenu logout={logout} />
            </Toolbar>
        </TrovoBar>
    );
}
