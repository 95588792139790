import {HighlightOutlined} from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {Box, Divider, Link, Typography} from '@mui/material';
import React from 'react';

interface BannerMessageProps {
    show: boolean;
    setHide?: () => void;
    title?: string;
    message?: string;
    links?: {
        text: string;
        href: string;
    }[];
    showClose?: boolean;
}

const BannerMessage: React.FC<BannerMessageProps> = ({show, setHide, title, message, links, showClose = true}) => {
    return (
        <Box
            flexDirection="row"
            borderRadius="12px"
            border="1px solid"
            borderColor="outline-variant"
            bgcolor="surface-container-highest"
            marginBottom="-14px"
            paddingLeft="16px"
            display={show ? 'flex' : 'none'}>
            <Box display="flex" alignItems="center" padding="8px">
                <HighlightOutlined sx={{color: 'on-surface', fontSize: '40px'}} />
            </Box>
            <Divider orientation="vertical" sx={{height: '88px', margin: 'auto 16px', borderColor: 'outline-variant'}} flexItem />
            <Box padding="16px 16px 16px 8px">
                <Typography variant="title" size="medium" color="on-surface">
                    {title}
                </Typography>
                <Typography variant="body" size="medium" margin="6px 0" color="on-surface-variant">
                    {message}
                </Typography>
                {links && (
                    <Box display="flex" flexDirection="row" gap="24px" marginTop="35px">
                        {links?.map((link, index) => (
                            <Typography
                                variant="title"
                                key={index}
                                size="small"
                                color="on-surface"
                                component={Link}
                                href={link.href}
                                sx={{textDecoration: 'none'}}>
                                {link.text}
                            </Typography>
                        ))}
                    </Box>
                )}
            </Box>
            {showClose && (
                <Box
                    display="flex"
                    alignItems="center"
                    padding="0 16px"
                    borderLeft="1px solid"
                    sx={{
                        borderLeftColor: 'outline-variant',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                    onClick={setHide}>
                    <HighlightOffIcon />
                </Box>
            )}
        </Box>
    );
};

export default BannerMessage;
