import React, {useEffect, useState} from 'react';
import {Card, CardHeader, CardContent, Typography, TextField, Button, Box} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Note from './Note';
import {getAppConfig, useTrovoConfig} from '../../../utils/config';
import {useAuth0} from '@auth0/auth0-react';

type NotesProps = {
    experimentId: string | undefined;
    formatTime: any;
};

export function Notes({experimentId, formatTime}: NotesProps) {
    type Note = {
        id: string | undefined;
        title: string | undefined;
        created_time: string;
        message: string;
        message_id?: string;
    }[];
    const {user} = useTrovoConfig();
    const [notes, setNotes] = useState<Note>([]);
    const {getAccessTokenSilently} = useAuth0();
    const [newNote, setNewNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editingNoteIndex, setEditingNoteIndex] = useState<number | null>(null);
    const {apiHost} = getAppConfig();

    const apiCall = async ({
        endpointSuffix,
        method,
        bodyData
    }: {
        endpointSuffix: string;
        method: 'GET' | 'POST' | 'PUT' | 'DELETE';
        bodyData?: any;
    }) => {
        setIsLoading(true);
        try {
            const endpoint = `${apiHost}/experiment/${experimentId}/note${endpointSuffix}`;
            const response = await fetch(endpoint, {
                method: method,
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: bodyData ? JSON.stringify(bodyData) : undefined
            });
            const responseData = await response.json();
            if (response.ok) {
                return responseData;
            } else {
                console.error(responseData.message || 'An error occurred.');

                return null;
            }
        } catch (err: any) {
            console.error(err.message || 'An error occurred.');

            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const getNotes = async () => {
        const responseData = await apiCall({endpointSuffix: '', method: 'GET'});
        if (responseData) setNotes(responseData);
    };

    const createNote = async (newNoteObj: any) => {
        const responseData = await apiCall({endpointSuffix: '', method: 'POST', bodyData: newNoteObj});
        if (responseData) setNotes(prev => [...prev, responseData]);
    };

    const updateNote = async (note: any) => {
        const responseData = await apiCall({endpointSuffix: `/${note.message_id}`, method: 'PUT', bodyData: note});
        if (responseData) {
            const updatedNotes = notes.map(item => (item.message_id !== note.message_id ? item : note));
            setNotes(updatedNotes);
        }
    };

    const deleteNote = async ({message_id}: {message_id: string}) => {
        const responseData = await apiCall({endpointSuffix: `/${message_id}/delete`, method: 'PUT'});
        if (responseData) {
            const updatedNotes = notes.filter(item => item.message_id !== message_id);
            setNotes(updatedNotes);
        }
    };

    useEffect(() => {
        getNotes();
    }, []);

    const addNote = () => {
        if (newNote.trim() !== '') {
            const newNoteObj = {
                id: experimentId,
                title: user?.nickname,
                created_time: new Date().toISOString().slice(0, 10),
                message: newNote
            };
            createNote(newNoteObj);
            setNewNote('');
            setEditingNoteIndex(null);
        }
    };

    const editNote = (index: number) => {
        setEditingNoteIndex(index);
    };
    const cancelEdit = () => {
        setEditingNoteIndex(null);
    };
    const saveEdit = (index: number, editedTitle: string, editedText: string) => {
        const updatedNotes = [...notes];
        updatedNotes[index].title = editedTitle;
        updatedNotes[index].message = editedText;
        setNotes(updatedNotes);
        updateNote({
            ...updatedNotes[index],
            title: editedTitle,
            message: editedText
        });
        setEditingNoteIndex(null);
    };

    const onDeleteNote = (note: any) => deleteNote(note);

    return (
        <Card sx={{bgcolor: 'surface-container-lowest', borderRadius: '12px', margin: '2% 0'}}>
            <CardHeader
                sx={{bgcolor: 'surface-container-high'}}
                title={
                    <Typography variant="title" size="small">
                        Notes
                    </Typography>
                }
            />
            <CardContent>
                {notes
                    .sort((a, b) => parseInt(a.created_time) - parseInt(b.created_time))
                    .map((note, index) => (
                        <Note
                            key={note.message_id}
                            note={note}
                            index={index}
                            onEditNote={() => editNote(index)}
                            onCancelEdit={cancelEdit}
                            onSaveEdit={saveEdit}
                            onDeleteNote={() => onDeleteNote(note)}
                            isEditing={editingNoteIndex === index}
                            formatTime={formatTime}
                        />
                    ))}
                <Box px={2}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="medium"
                        placeholder="Add a note"
                        value={newNote}
                        onChange={e => setNewNote(e.target.value)}
                        onFocus={() => setEditingNoteIndex(notes.length)} // Expand input on focus
                        InputProps={{
                            endAdornment: (
                                <Button variant="text" onClick={addNote} startIcon={<AddIcon />}>
                                    Add
                                </Button>
                            )
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default Notes;
