import React, {useState} from 'react';
import {Typography, IconButton, Menu, MenuItem, TextField, Button, Box} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {ExpandMore, ExpandLess} from '@mui/icons-material';

interface NoteProps {
    note: {
        id: string | undefined;
        title: string | undefined;
        created_time: string;
        message: string;
        message_id?: string;
    };
    index: number;
    onEditNote: (index: number) => void;
    onSaveEdit: (index: number, editedTitle: string, editedText: string) => void;
    onCancelEdit: () => void;
    onDeleteNote: (index: number) => void;
    isEditing: boolean;
    formatTime: any;
}

const Note: React.FC<NoteProps> = ({note, index, onEditNote, onSaveEdit, onCancelEdit, onDeleteNote, isEditing, formatTime}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [expanded, setExpanded] = useState(false);
    const [editedTitle, setEditedTitle] = useState(note.title);
    const [editedText, setEditedText] = useState(note.message);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleMenuClose = () => setAnchorEl(null);

    return (
        <Box my={'1px'} mx={2} pb={1} borderBottom={1} borderColor={'outline'}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: ' 0'}}>
                <Typography variant="body" size="large" sx={{color: 'on-surface'}}>
                    {note.title}
                </Typography>
                <Box sx={{display: 'flex', alignItems: 'center'}} py={0}>
                    <Typography variant="body" size="large" sx={{color: 'on-surface'}}>
                        {formatTime(note.created_time)}
                    </Typography>
                    <IconButton onClick={event => handleMenuOpen(event)}>
                        <MoreVertIcon />
                    </IconButton>
                </Box>
            </div>
            <Typography variant="body" size="large">
                {note.message.length > 150 && !expanded ? `${note.message.slice(0, 150)}...` : editedText}
                {note.message.length > 150 && (
                    <IconButton onClick={() => setExpanded(!expanded)} size="small">
                        {!expanded ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                )}
            </Typography>
            {isEditing ? (
                <div>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={editedText}
                        onChange={e => setEditedText(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <Box sx={{display: 'flex'}}>
                                    <Button onClick={() => onSaveEdit(index, editedTitle || '', editedText)}>Save</Button>
                                    <Button onClick={() => onCancelEdit()}>Cancel</Button>
                                </Box>
                            )
                        }}
                    />
                </div>
            ) : null}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => onEditNote(index)}>
                    <EditIcon />
                    Edit
                </MenuItem>
                <MenuItem onClick={() => onDeleteNote(index)}>
                    <DeleteIcon />
                    Delete
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default Note;
